import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, signal } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'error-page',
  standalone: true,
  imports: [MatProgressSpinner],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorPageComponent implements OnInit {
  constructor(private router:Router, private activatedRoute:ActivatedRoute) {
  }
  message = signal("You do not have access to the journey, please contact your admin to get access.");

  @Output() readonly showHeaderAndNavigation = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.showHeaderAndNavigation.emit(false);

    const message = this.activatedRoute.snapshot.queryParamMap.get('message');

    if (message) {
      this.message.set(message);
    }
  }
}
